import { debounce } from 'lodash-es'

const initUI = () => {
  // That sad but true
  // We have two initializers - one for class=datepicker and one for role=datepicker
  // see base.js document.on(ready)

  // Use <div class="datepicker js-bootstrap-disable"> for disable bootstrap datepicker

  $('.datepicker:not(.js-bootstrap-disable)').datepicker({
    format: 'yyyy-mm-dd',
    autoclose: true,
    todayHighlight: true,
  })

  // For fluid containers
  $('.datatable:not(.dataTable)').each(function () {
    window.activateDataTable(this)
  })

  $('.js_approval_mappings_datatable:not(.dataTable)').dataTable({
    bInfo: false,
    bLengthChange: false,
    bPaginate: false,
    bRetrieve: true,
  })

  $('.data_usage:not(.dataTable)').dataTable({
    aaSorting: [[5, 'desc']],
    iDisplayLength: 25,
    bRetrieve: true,
  })

  const travelRequestsTable = $('.datatable-travel-requests:not(.dataTable)').dataTable({
    pagingType: 'full_numbers',
    bRetrieve: true,
    bLengthChange: false,
    dom: 'Bfrtip', // Add the Buttons extension
    buttons: [
      {
        extend: 'csv',
        text: 'Export to CSV',
        className: 'btn btn-datatable-export',
        action: function (e, dt) {
          // Custom csv export function
          const data = dt.rows({ search: 'applied' }).data().toArray()
          const csvDataHeaders = '"Date & Employee", Travelers,,, Status'
          const tmp = data.map((row) => {
            const col1 = `"${row[0]?.display?.split('\n')?.[0]}"`
            const parser = new DOMParser()
            const doc = parser.parseFromString(row[1], 'text/html')
            const i = doc.querySelector('i')
            const lastCol = i ? `"${i.innerText.replace(/\n/g, '')}"` : ''
            const trs = Array.from(doc.querySelectorAll('tr'))
            const innerRows = trs.map((tr) => {
              const tds = Array.from(tr.querySelectorAll('td'))
              const innerCols = tds.map((td) => `"${td.innerText.replace(/\n/g, ' ').trim()}"`)
              return [col1, ...innerCols, lastCol]
            })
            return innerRows.join('\n')
          })
          tmp.unshift(csvDataHeaders)
          const csvData = tmp.join('\n')
          const csvBlob = new Blob(['\uFEFF' + csvData], { type: 'text/csv;charset=utf-8;' }) // Add BOM for UTF-8
          const csvUrl = URL.createObjectURL(csvBlob)
          const a = document.createElement('a')
          a.href = csvUrl
          a.download = 'Travel Requests Export.csv'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        },
      },
    ],
  })
  /**
   * Add status filter to travel requests table
   */
  if ($('[data-status-filter="true"]').length && !$('#status-filter')?.length) {
    $('.dataTables_filter').append(
      '<label style="display: flex; align-items: center; white-space: nowrap;"><span style="margin-right: 4px;">Filter by Status: </span><select id="status-filter" multiple><option>Added</option><option>Cancelled</option><option>Pending confirmation</option><option>Pending cancellation</option><option>Waiting for approval</option><option>Reoccurring</option></select></label>'
    )
    const $statusFilter = $('#status-filter')
    const el = $statusFilter.get(0)
    if (el) {
      el.style.maxWidth = '400px'
    }
    $statusFilter.select2({
      allowClear: true,
    })
    $('#status-filter').on('change', function (e) {
      const statuses = e.val
      if (statuses?.length > 0) {
        // Do a case insensitive regex search for the statuses
        travelRequestsTable.api().column(1).search(statuses.join('|'), true, false).draw()
      } else if (statuses) {
        travelRequestsTable.api().column(1).search(statuses.join('.*'), true, false).draw()
      }
    })
    $('.datatable-travel-requests tbody tr').each(function () {
      this.classList.remove('odd')
      this.classList.remove('even')
    })
  }

  // TODO: We have a few different initializers for select2.
  // see base.js document.on(ready)
  $('select.select2').each(function () {
    const $el = $(this)
    let options = {}
    if ($el.data('select2-disable-search')) {
      options.minimumResultsForSearch = -1
    }
    const args = $el.data('select2-options')
    if (args && typeof args == 'object') {
      options = $.extend(options, args)
    }
    $el.select2(options)
  })

  $('.has-popover').each(function () {
    $(this).trigger('argon:createPopover')
  })

  $('@popover').each(function () {
    $(this).trigger('argon:createPopover')
  })

  $('.f-dropdown[data-dropdown-content]').appendTo($('body'))

  // TODO: looks like it will triggered on the full document
  // if data-replace elements not found.
  // so any ajax:success will trigger 'ajax:replaced'
  $('[data-remote][data-replace]')
    .data('type', 'html')
    .on('ajax:success', function (event, data) {
      const $this = $(this)
      $($this.data('replace')).html(data)
      $this.trigger('ajax:replaced')
    })
}

// Use _.debounce in order to reduce `initUI` calls
$(document).on(
  'init.argon.ui',
  debounce((event) => initUI(event.target), 250)
)
