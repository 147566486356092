import { isEmpty } from 'lodash-es'

// You can use angular directive `selected-object` directive to set initial value
export class EmployeeContactsSearch {
  constructor(element) {
    this.element = element
  }

  activate() {
    const $input = $(this.element)

    const embrace = (val) => (isEmpty(val) ? '' : ` (${val})`)

    $input.select2({
      minimumInputLength: 3,
      allowClear: true,
      placeholder: $input.attr('placeholder'),
      url: $input.data('action'),
      ajax: {
        url: $input.data('action'),
        dataType: 'json',
        delay: 250,
        cache: true,
        data: (params) => ({ q: params }),
        results: (data) => ({ results: data.items }),
      },
      formatNoMatches: () => '',
      formatResult: (employee) => {
        const notes = embrace(employee.notes)
        const number = embrace(employee.employee_number)
        return `${employee.name}${number}${notes}`
      },
      formatSelection: (employee) => {
        const number = embrace(employee.employee_number)
        return `${employee.name}${number}`
      },
      initSelection: (element, callback) => {
        const data = { name: element.val() }
        if (!data.name) return

        setTimeout(() => callback(data), 100)
      },
    })
  }
}
