import { isEmpty, each, isUndefined } from 'lodash-es'
import { DateHelper } from './date_helper'

export class MiradoreInfo {
  static boolToWord = (value) => {
    if (isUndefined(value)) return
    if (value == 'True') return 'Yes'
    return 'No'
  }

  static ownership = (abbreviation) => {
    switch (abbreviation) {
      case 'C':
        return 'Corporate Owned'
      case 'S':
        return 'Corporate Shared'
      case 'E':
        return 'Employee Owned'
    }
  }

  static displayDate = (dateTime) => DateHelper.displayDate(dateTime)

  static digValue = (obj, path) => {
    if (isEmpty(obj)) return
    let current = obj
    each(path.split('.'), (fragment) => {
      if (current[fragment] === undefined) {
        current = 'no data'
        return
      } else {
        current = current[fragment]
      }
    })
    return current
  }

  static mapping = {
    id: {
      title: 'Miradore ID',
      path: 'ID',
    },
    imei: {
      title: 'IMEI',
      path: 'InvDevice.IMEI',
    },
    model: {
      title: 'Model',
      path: 'InvDevice.Model',
    },
    last_reported: {
      title: 'Last Reported',
      path: 'LastReported',
    },
    platform_name: {
      title: 'Platform name',
      path: 'Platform',
    },
    username: {
      title: 'Username',
      path: 'User.Name',
    },
    mac_address: {
      title: 'Mac Address',
      path: 'MACAddress',
    },
    ip_address: {
      title: 'IP Address',
      path: 'IPAddress',
    },
    phone_number: {
      title: 'Phone Number',
      path: 'phone_number',
    },
    serial_number: {
      title: 'Serial Number',
      path: 'InvDevice.SerialNumber',
    },
    roaming_status: {
      title: 'In Roaming',
      path: 'InvOperatorNetwork.CurrentRoamingState',
      formatter: MiradoreInfo.boolToWord,
    },
    os_version: {
      title: 'OS Version',
      path: 'InvDevice.SoftwareVersion',
    },
    enrollment_completed: {
      title: 'Enrollment Completed',
      path: 'Enrollment.Completed',
    },
    enrollment_sender: {
      title: 'Enrollment Sender',
      path: 'Enrollment.Sender',
    },
    email_address: {
      title: 'Email',
      path: 'User.Email',
    },
    sim_imsi: {
      title: 'SIM IMSI',
      path: 'InvSIM.IMSI',
    },
    compromised_status: {
      title: 'Compromised',
      path: 'CompromisedStatus',
      formatter: MiradoreInfo.boolToWord,
    },
    last_compromised_check_on: {
      title: 'Last Compromised Check On',
      path: 'LastCompromisedCheckOn',
      formatter: MiradoreInfo.displayDate,
    },
    current_carrier: {
      title: 'Carrier',
      path: 'InvOperatorNetwork.OperatorName',
    },
  }

  static groupedAttributes = {
    short: [
      {
        title: 'Device',
        attributeNames: ['imei', 'sim_imsi', 'serial_number'],
      },
      {
        title: 'Info',
        attributeNames: ['phone_number', 'username', 'roaming_status', 'email_address'],
      },
      {
        title: 'Miradore Status',
        attributeNames: ['id', 'last_reported'],
      },
    ],
    full: [
      {
        title: 'Device',
        attributeNames: [
          'imei',
          'sim_imsi',
          'serial_number',
          'model',
          'platform_name',
          'os_version',
          'mac_address',
          'ip_address',
        ],
      },
      {
        title: 'Info',
        attributeNames: [
          'phone_number',
          'username',
          'roaming_status',
          'email_address',
          'current_carrier',
        ],
      },
      {
        title: 'Miradore Status',
        attributeNames: ['id', 'last_reported', 'enrollment_completed', 'enrollment_sender'],
      },
    ],
  }

  static buttons = {
    short: ['data_update', 'installed_applications', 'change_username'],
    full: [
      'data_update',
      'installed_applications',
      'change_username',
      'location',
      'lost_mode_on',
      'lost_mode_off',
      'lock',
      'wipe',
    ],
  }

  static value = (name) => {
    const value = MiradoreInfo.digValue(MiradoreInfo.data.base, MiradoreInfo.mapping[name].path)
    if (!MiradoreInfo.mapping[name].formatter) return value
    return MiradoreInfo.mapping[name].formatter(value)
  }

  prepareData(mode) {
    const result = []
    each(MiradoreInfo.groupedAttributes[mode], (group) => {
      const groupedAttrs = { attributes: [], title: group.title }
      each(group.attributeNames, (name) => {
        const value = MiradoreInfo.value(name)
        if (value) {
          const obj = { title: MiradoreInfo.mapping[name].title, value: value }
          groupedAttrs.attributes.push(obj)
        }
      })
      result.push(groupedAttrs)
    })
    return result
  }

  prepareButtons = (mode) => {
    const result = {}
    each(MiradoreInfo.buttons[mode], (name) => (result[name] = true))
    return result
  }

  constructor(data) {
    MiradoreInfo.data = data
    this.updatedAt = DateHelper.displayDate(new Date(Date.parse(data.updated_at)))
    this.displayMode = 'full'
    this.displayData = {
      attributeGroups: this.prepareData(this.displayMode),
      buttons: this.prepareButtons(this.displayMode),
    }
  }

  getAttributeRawValue(name) {
    return MiradoreInfo.data.base[name]
  }

  switchViewMode() {
    this.displayMode = this.displayMode === 'short' ? 'full' : 'short'
    this.displayData.attributeGroups = this.prepareData(this.displayMode)
    this.displayData.buttons = this.prepareButtons(this.displayMode)
  }
}
