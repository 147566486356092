import { isUndefined, isEmpty, each } from 'lodash-es'
import { DateHelper } from './date_helper'

export class AirwatchInfo {
  static boolToWord = (value) => {
    if (isUndefined(value)) return
    if (value) return 'Yes'
    return 'No'
  }

  static ownership = (abbreviation) => {
    switch (abbreviation) {
      case 'C':
        return 'Corporate Owned'
      case 'S':
        return 'Corporate Shared'
      case 'E':
        return 'Employee Owned'
    }
  }

  static displayDate = (dateTime) => DateHelper.displayDate(dateTime)

  static mapping = {
    id: {
      title: 'AirWatch ID',
      path: 'Id.Value',
    },
    imei: {
      title: 'IMEI',
      path: 'Imei',
    },
    model: {
      title: 'Model',
      path: 'Model',
    },
    last_seen: {
      title: 'Last Seen',
      path: 'LastSeen',
      formatter: AirwatchInfo.displayDate,
    },
    platform_name: {
      title: 'Platform name',
      path: 'Platform',
    },
    username: {
      title: 'Username',
      path: 'UserName',
    },
    friendly_name: {
      title: 'Device Name',
      path: 'DeviceFriendlyName',
    },
    wifi_mac_address: {
      title: 'WiFi Mac Address',
      path: 'WifiInfo.WifiMacAddress',
    },
    ip_address: {
      title: 'IP Address',
      path: 'IPAddress.WifiIPAddress',
    },
    ownership: {
      title: 'Ownership',
      path: 'Ownership',
      formatter: AirwatchInfo.ownership,
    },
    phone_number: {
      title: 'Phone Number',
      path: 'PhoneNumber',
    },
    serial_number: {
      title: 'Serial Number',
      path: 'SerialNumber',
    },
    roaming_status: {
      title: 'In Roaming',
      path: 'RoamingStatus',
      formatter: AirwatchInfo.boolToWord,
    },
    os_version: {
      title: 'OS Version',
      path: 'OperatingSystem',
    },
    compliance_state: {
      title: 'Compliance state',
      path: 'ComplianceStatus',
    },
    enrollment_status: {
      title: 'Enrollment Status',
      path: 'EnrollmentStatus',
    },
    email_address: {
      title: 'Email',
      path: 'UserEmailAddress',
    },
    sim: {
      title: 'SIM',
      path: 'CellularNetworkInfo.CurrentSIM',
    },
    compromised_status: {
      title: 'Compromised',
      path: 'CompromisedStatus',
      formatter: AirwatchInfo.boolToWord,
    },
    last_compromised_check_on: {
      title: 'Last Compromised Check On',
      path: 'LastCompromisedCheckOn',
      formatter: AirwatchInfo.displayDate,
    },
    current_carrier: {
      title: 'Carrier',
      path: 'CellularNetworkInfo.CurrentOperator',
    },
  }

  static groupedAttributes = {
    short: [
      {
        title: 'Device',
        attributeNames: ['imei', 'sim', 'serial_number'],
      },
      {
        title: 'Info',
        attributeNames: ['phone_number', 'roaming_status', 'email_address'],
      },
      {
        title: 'AirWatch Status',
        attributeNames: ['id', 'enrollment_status', 'last_seen'],
      },
    ],
    full: [
      {
        title: 'Device',
        attributeNames: [
          'imei',
          'sim',
          'model',
          'platform_name',
          'os_version',
          'wifi_mac_address',
          'ip_address',
          'serial_number',
        ],
      },
      {
        title: 'Info',
        attributeNames: [
          'friendly_name',
          'username',
          'ownership',
          'phone_number',
          'roaming_status',
          'email_address',
          'compliance_state',
          'compromised_status',
          'current_carrier',
        ],
      },
      {
        title: 'AirWatch Status',
        attributeNames: ['id', 'enrollment_status', 'last_compromised_check_on', 'last_seen'],
      },
    ],
  }

  static buttons = {
    short: ['data_update', 'send_message', 'installed_applications'],
    full: [
      'data_update',
      'sync',
      'send_message',
      'profiles',
      'compliance',
      'installed_applications',
      'remote_lock',
      'full_wipe',
      'enterprise_wipe',
      'clear_passcode',
    ],
  }

  static digValue = (obj, path) => {
    if (isEmpty(obj)) return
    let current = obj
    each(path.split('.'), (fragment) => {
      if (current[fragment] === undefined) return undefined
      else current = current[fragment]
    })
    return current
  }

  static value = (name) => {
    const value = AirwatchInfo.digValue(AirwatchInfo.data.base, AirwatchInfo.mapping[name].path)
    if (!AirwatchInfo.mapping[name].formatter) return value
    return AirwatchInfo.mapping[name].formatter(value)
  }

  constructor(data) {
    AirwatchInfo.data = data
    this.updatedAt = DateHelper.displayDate(new Date(Date.parse(data.updated_at)))
    this.displayMode = 'full'
    this.displayData = {
      attributeGroups: this.prepareData(this.displayMode),
      buttons: this.prepareButtons(this.displayMode),
    }
  }

  prepareData(mode) {
    let result = []
    each(AirwatchInfo.groupedAttributes[mode], (group) => {
      const groupedAttrs = { attributes: [], title: group.title }
      each(group.attributeNames, (name) => {
        const value = AirwatchInfo.value(name)
        if (value) {
          const obj = { title: AirwatchInfo.mapping[name].title, value: value }
          groupedAttrs.attributes.push(obj)
        }
      })
      result.push(groupedAttrs)
    })
    return result
  }

  prepareButtons(mode) {
    const result = {}
    each(AirwatchInfo.buttons[mode], (name) => (result[name] = true))
    return result
  }

  getAttributeRawValue(name) {
    return AirwatchInfo.data.base[name]
  }

  switchViewMode() {
    this.displayMode = this.displayMode === 'short' ? 'full' : 'short'
    this.displayData.attributeGroups = this.prepareData(this.displayMode)
    this.displayData.buttons = this.prepareButtons(this.displayMode)
  }
}
