export const defaultTagColor = 'C3C5CD'
export const tagColors = [
  defaultTagColor,
  'F8C6C8',
  'FDDCC5',
  'D6CDC1',
  'F7E8B5',
  'DAECC8',
  'BCE3D8',
  'CAF0F6',
  'ABD3FF',
  'ABC4D7',
  'DFD3EE',
]
