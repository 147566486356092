import { customer_settings_accounting_type_accounting_categories_path } from '~/helpers/rails-routes'

$(() => new UserNew($('@userNew')))

class UserNew {
  /**
   *
   * @param {JQuery} $container
   */
  constructor($container) {
    if ($container.length === 0) return
    const customer_id = $('body').data('customer-id')
    const $userRestrictions = $container.find('@userRestrictionsAccountingCategories')
    if ($userRestrictions.length === 0) return
    const type_id = $userRestrictions.data('type').id

    $userRestrictions.select2({
      initSelection: (element, callback) => {
        let data = element.data('default')[0]
        if (data && data[0]) {
          data = { id: data[0], text: data[1] }
          element.val(data[0])
        }
        callback(data)
      },
      ajax: {
        placeholder: 'Select category...',
        url: customer_settings_accounting_type_accounting_categories_path(customer_id, type_id),
        dataType: 'json',
        delay: 250,
        data: (params) => ({ term: params, page_limit: 10 }),
        results: (data) => {
          return {
            results: $.map(data.data, (item) => ({ text: item.name, id: item.id })),
          }
        },
        cache: true,
      },
      multiple: true,
      minimumInputLength: 1,
      formatResult: this.formatRepo.bind(this),
      formatSelection: this.formatRepoSelection.bind(this),
    })
  }

  formatRepo(data) {
    return `<div data-id=${data.id} role='accountingCategoryOption'>` + data.text + '</div>'
  }

  formatRepoSelection(data) {
    return (
      `<span role='currentAccountingCategory' data-category='${JSON.stringify(data.id)}'>` +
      `${data.text} </span>`
    )
  }
}
