import { each } from 'lodash-es'
import { DateHelper } from './date_helper'

export class Maas360Info {
  static displayDate = (dateTime) => DateHelper.displayDate(dateTime)

  static mapping = {
    device_type: {
      title: 'Device type',
    },
    manufacturer: {
      title: 'Manufacturer',
    },
    model: {
      title: 'Model',
    },
    imei_esn: {
      title: 'IMEI',
    },
    wifi_mac_address: {
      title: 'WiFi Mac Address',
    },
    device_serial_number: {
      title: 'Device Serial Number',
    },
    total_free_storage_gb: {
      title: 'Total Free Storage (Gb)',
    },
    platform_name: {
      title: 'Platform name',
    },
    os_name: {
      title: 'OS',
    },
    os_service_pack: {
      title: 'OS service pack',
    },
    device_rooted: {
      title: 'Rooted',
    },
    device_name: {
      title: 'Device name',
    },
    username: {
      title: 'Username',
    },
    email_address: {
      title: 'Email',
    },
    ownership: {
      title: 'Ownership',
    },
    device_owner: {
      title: 'Owner',
    },
    sim: {
      title: 'SIM',
    },
    home_carrier: {
      title: 'Home Carrier',
    },
    current_carrier: {
      title: 'Current Carrier',
    },
    phone_number: {
      title: 'Phone Number',
    },
    mdm_policy: {
      title: 'MDM Policy',
    },
    policy_compliance_state: {
      title: 'Policy Compliance State',
    },
    device_passcode_status: {
      title: 'Device Passcode Status',
    },
    out_of_compliance_reasons: {
      title: 'Out of Compliance Reasons',
    },
    device_status: {
      title: 'Status',
    },
    mailbox_managed: {
      title: 'Mailbox managed',
    },
    mailbox_device_id: {
      title: 'Mailbox Device ID',
    },
    data_roaming: {
      title: 'Data Roaming',
    },
    background_data_sync_enabled: {
      title: 'Background Data Sync Enabled',
    },
    last_reported_roaming_status: {
      title: 'Last Reported Roaming Status',
    },
    international_data_roaming_enabled: {
      title: 'International Data Roaming Enabled',
    },
    maas360_managed_status: {
      title: 'Maas360 Managed Status',
    },
    exchange_activated: {
      title: 'Exchange Activated',
    },
    exchange_approval_state: {
      title: 'Exchange Approval State',
    },
    maas360_device_id: {
      title: 'MaaS360 ID',
    },
    mailbox_last_reported: {
      title: 'Mailbox Last Reported',
      formatter: Maas360Info.displayDate,
    },
    last_reported: {
      title: 'Last Reported',
      formatter: Maas360Info.displayDate,
    },
    installed_date: {
      title: 'Installed Date',
      formatter: Maas360Info.displayDate,
    },
  }

  static groupedAttributes = {
    short: [
      {
        title: 'Device',
        attributeNames: ['device_serial_number', 'device_status'],
      },
      {
        title: 'Info',
        attributeNames: ['username', 'international_data_roaming_enabled', 'data_roaming'],
      },
      {
        title: 'MaaS360 Status',
        attributeNames: ['maas360_device_id', 'policy_compliance_state', 'maas360_managed_status'],
      },
    ],
    full: [
      {
        title: 'Device',
        attributeNames: [
          'device_serial_number',
          'device_status',
          'device_type',
          'model',
          'manufacturer',
          'imei_esn',
          'platform_name',
          'os_service_pack',
          'os_name',
          'device_name',
          'device_rooted',
          'wifi_mac_address',
          'total_free_storage_gb',
        ],
      },
      {
        title: 'Info',
        attributeNames: [
          'international_data_roaming_enabled',
          'data_roaming',
          'last_reported_roaming_status',
          'current_carrier',
          'home_carrier',
          'sim',
          'phone_number',
          'device_owner',
          'username',
          'ownership',
          'email_address',
        ],
      },
      {
        title: 'MaaS360 Status',
        attributeNames: [
          'maas360_device_id',
          'policy_compliance_state',
          'maas360_managed_status',
          'mailbox_device_id',
          'mailbox_managed',
          'mailbox_last_reported',
          'exchange_activated',
          'mdm_policy',
          'background_data_sync_enabled',
          'out_of_compliance_reasons',
          'exchange_approval_state',
          'last_reported',
          'installed_date',
          'device_passcode_status',
        ],
      },
    ],
  }

  static buttons = {
    short: ['enroll_device', 'security_compliance', 'installed_applications'],
    full: [
      'enroll_device',
      'data_update',
      'security_compliance',
      'installed_applications',
      'refresh_device_info',
      'lock_device',
      'wipe_device',
      'cancel_pending_wipe',
      'remove_mailbox_server',
      'reset_passcode',
      'send_message',
      'change_policy',
      'location_history',
    ],
  }

  static value = (name) => {
    if (!Maas360Info.mapping[name].formatter) return Maas360Info.data.base[name]
    return Maas360Info.mapping[name].formatter(Maas360Info.data.base[name])
  }

  constructor(data) {
    Maas360Info.data = data
    this.updatedAt = DateHelper.displayDate(new Date(Date.parse(data.updated_at)))
    this.displayMode = 'full'
    this.managedStatus = data.base.maas360_managed_status
    this.displayData = {
      attributeGroups: this.prepareData(this.displayMode),
      buttons: this.prepareButtons(this.displayMode),
    }
  }

  prepareData(mode) {
    const result = []
    each(Maas360Info.groupedAttributes[mode], (group) => {
      const groupedAttrs = { attributes: [], title: group.title }
      each(group.attributeNames, (name) => {
        const value = Maas360Info.value(name)
        if (value) {
          const obj = { title: Maas360Info.mapping[name].title, value: value }
          groupedAttrs.attributes.push(obj)
        }
      })
      result.push(groupedAttrs)
    })
    return result
  }

  prepareButtons(mode) {
    const result = {}
    each(Maas360Info.buttons[mode], (name) => (result[name] = true))
    return result
  }

  getAttributeRawValue(name) {
    return Maas360Info.data.base[name]
  }

  switchViewMode() {
    this.displayMode = this.displayMode === 'short' ? 'full' : 'short'
    this.displayData.attributeGroups = this.prepareData(this.displayMode)
    this.displayData.buttons = this.prepareButtons(this.displayMode)
  }
}
