import { isEmpty, each } from 'lodash-es'
import { DateHelper } from './date_helper'

export class SotiInfo {
  static displayArray = (value) => value.join(', ')

  static displayDate = (dateTime) => DateHelper.displayDate(dateTime)

  static mapping = {
    id: {
      title: 'Soti ID',
      path: 'DeviceId',
    },
    phone_number: {
      title: 'Phone Number',
      path: 'PhoneNumber',
    },
    name: {
      title: 'Device Name',
      path: 'DeviceName',
    },
    manufacturer: {
      title: 'Manufacturer',
      path: 'Manufacturer',
    },
    model: {
      title: 'Model',
      path: 'Model',
    },
    imei: {
      title: 'IMEI',
      path: 'IMEI_MEID_ESN',
    },
    os_version: {
      title: 'OS Version',
      path: 'OSVersion',
    },
    platform_name: {
      title: 'Platform name',
      path: 'Platform',
    },
    last_sync: {
      title: 'Last Sync',
      path: 'LastCheckInTime',
      formatter: SotiInfo.displayDate,
    },
    serial_number: {
      title: 'Serial Number',
      path: 'HardwareSerialNumber',
    },
    mac_address: {
      title: 'Mac Address',
      path: 'MACAddress',
    },
  }

  static groupedAttributes = {
    short: [
      {
        title: 'Device',
        attributeNames: ['imei', 'serial_number', 'manufacturer', 'model'],
      },
      {
        title: 'Info',
        attributeNames: ['name', 'phone_number'],
      },
      {
        title: 'Soti Status',
        attributeNames: ['id', 'last_sync'],
      },
    ],
    full: [
      {
        title: 'Device',
        attributeNames: [
          'imei',
          'serial_number',
          'manufacturer',
          'model',
          'platform_name',
          'os_version',
          'mac_address',
        ],
      },
      {
        title: 'Info',
        attributeNames: ['name', 'phone_number'],
      },
      {
        title: 'Status',
        attributeNames: ['id', 'last_sync'],
      },
    ],
  }

  static buttons = {
    short: ['check_in', 'locate'],
    full: ['check_in', 'locate', 'delete', 'disable', 'factory_reset', 'unenroll', 'wipe'],
  }

  static value = (name) => {
    const value = SotiInfo.digValue(SotiInfo.data.base, SotiInfo.mapping[name].path)
    if (!SotiInfo.mapping[name].formatter) return value
    return SotiInfo.mapping[name].formatter(value)
  }

  static digValue = (obj, path) => {
    if (isEmpty(obj)) return
    let current = obj
    each(path.split('.'), (fragment) => {
      if (current[fragment] === undefined) {
        current = 'no data'
        return
      } else {
        current = current[fragment]
      }
    })
    return current
  }

  prepareData(mode) {
    const result = []
    each(SotiInfo.groupedAttributes[mode], (group) => {
      const groupedAttrs = { attributes: [], title: group.title }
      each(group.attributeNames, (name) => {
        const value = SotiInfo.value(name)
        if (value) {
          const obj = { title: SotiInfo.mapping[name].title, value: value }
          groupedAttrs.attributes.push(obj)
        }
      })
      result.push(groupedAttrs)
    })
    return result
  }

  prepareButtons(mode) {
    const result = {}
    each(SotiInfo.buttons[mode], (name) => (result[name] = true))
    return result
  }

  constructor(data) {
    SotiInfo.data = data
    this.updatedAt = DateHelper.displayDate(new Date(Date.parse(data.updated_at)))
    this.displayMode = 'full'
    this.displayData = {
      attributeGroups: this.prepareData(this.displayMode),
      buttons: this.prepareButtons(this.displayMode),
    }
  }

  getAttributeRawValue(name) {
    return SotiInfo.data.base[name]
  }

  switchViewMode() {
    this.displayMode = this.displayMode === 'short' ? 'full' : 'short'
    this.displayData.attributeGroups = this.prepareData(this.displayMode)
    this.displayData.buttons = this.prepareButtons(this.displayMode)
  }
}
