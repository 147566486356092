import { isEmpty, each } from 'lodash-es'
import { DateHelper } from './date_helper'

export class GSuiteInfo {
  static displayArray = (value) => value.join(', ')

  static displayDate = (dateTime) => DateHelper.displayDate(dateTime)

  static digValue = (obj, path) => {
    if (isEmpty(obj)) return
    let current = obj
    each(path.split('.'), (fragment) => {
      if (current[fragment] === undefined) {
        current = 'no data'
        return
      } else {
        current = current[fragment]
      }
    })
    return current
  }

  static mapping = {
    id: {
      title: 'GSuite ID',
      path: 'resource_id',
    },
    name: {
      title: 'Name',
      path: 'name',
      formatter: GSuiteInfo.displayArray,
    },
    email: {
      title: 'Email',
      path: 'email',
      formatter: GSuiteInfo.displayArray,
    },
    manufacturer: {
      title: 'Manufacturer',
      path: 'manufacturer',
    },
    model: {
      title: 'Model',
      path: 'model',
    },
    imei: {
      title: 'IMEI',
      path: 'imei',
    },
    os_version: {
      title: 'OS Version',
      path: 'os',
    },
    platform_name: {
      title: 'Platform name',
      path: 'type',
    },
    status: {
      title: 'Status',
      path: 'status',
    },
    last_sync: {
      title: 'Last Sync',
      path: 'last_sync',
      formatter: GSuiteInfo.displayDate,
    },
    serial_number: {
      title: 'Serial Number',
      path: 'serial_number',
    },
    mac_address: {
      title: 'Mac Address',
      path: 'wifi_mac_address',
    },
    device_compromised_status: {
      title: 'Device Compromised Status',
      path: 'device_compromised_status',
    },
  }

  static groupedAttributes = {
    short: [
      {
        title: 'Device',
        attributeNames: ['imei', 'serial_number', 'manufacturer', 'model'],
      },
      {
        title: 'Info',
        attributeNames: ['name', 'email'],
      },
      {
        title: 'GSuite Status',
        attributeNames: ['id', 'last_sync', 'status'],
      },
    ],
    full: [
      {
        title: 'Device',
        attributeNames: [
          'imei',
          'serial_number',
          'manufacturer',
          'model',
          'platform_name',
          'os_version',
          'mac_address',
          'device_compromised_status',
        ],
      },
      {
        title: 'Info',
        attributeNames: ['name', 'email'],
      },
      {
        title: 'GSuite Status',
        attributeNames: ['id', 'last_sync', 'status'],
      },
    ],
  }

  static buttons = {
    short: ['approve', 'data_update'],
    full: [
      'admin_account_wipe',
      'admin_remote_wipe',
      'approve',
      'block',
      'cancel_remote_wipe_then_activate',
      'cancel_remote_wipe_then_block',
      'data_update',
      'delete',
    ],
  }

  static value = (name) => {
    const value = GSuiteInfo.digValue(GSuiteInfo.data.base, GSuiteInfo.mapping[name].path)
    if (!GSuiteInfo.mapping[name].formatter) return value
    return GSuiteInfo.mapping[name].formatter(value)
  }

  constructor(data) {
    GSuiteInfo.data = data
    this.updatedAt = DateHelper.displayDate(new Date(Date.parse(data.updated_at)))
    this.displayMode = 'full'
    this.displayData = {
      attributeGroups: this.prepareData(this.displayMode),
      buttons: this.prepareButtons(this.displayMode),
    }
  }

  prepareData(mode) {
    const result = []
    each(GSuiteInfo.groupedAttributes[mode], (group) => {
      const groupedAttrs = { attributes: [], title: group.title }
      each(group.attributeNames, (name) => {
        const value = GSuiteInfo.value(name)
        if (value) {
          const obj = { title: GSuiteInfo.mapping[name].title, value: value }
          groupedAttrs.attributes.push(obj)
        }
      })
      result.push(groupedAttrs)
    })
    return result
  }

  prepareButtons(mode) {
    const result = {}
    each(GSuiteInfo.buttons[mode], (name) => (result[name] = true))
    return result
  }

  getAttributeRawValue(name) {
    return GSuiteInfo.data.base[name]
  }

  switchViewMode() {
    this.displayMode = this.displayMode == 'short' ? 'full' : 'short'
    this.displayData.attributeGroups = this.prepareData(this.displayMode)
    this.displayData.buttons = this.prepareButtons(this.displayMode)
  }
}
