import angular from 'angular'
import swal from 'sweetalert'

angular.module('ArgonModule').controller('FinancialLeadCtrl', [
  '$scope',
  '$element',
  '$timeout',
  function ctrl($scope, $element, $timeout) {
    const data = $element.data('ngdata')

    $scope.mappings = data['mappings']
    const contacts = data['contacts']

    $scope.addMapping = () => {
      $scope.mappings.push({})
    }

    $scope.removeMapping = (mapping) => {
      const swal_params = {
        title: 'Delete mapping',
        text: 'Are you sure?',
        icon: 'warning',
        buttons: true,
      }

      swal(swal_params).then((isConfirmed) => {
        if (!isConfirmed) return

        $timeout(() => {
          if (mapping.id) mapping._destroy = 1
          else $scope.mappings.splice($scope.mappings.indexOf(mapping), 1)
        })
      })
    }

    $scope.reSendReport = (mapping, event) => {
      const swal_params = {
        title: 'Resend Report',
        text: 'Are you sure?',
        icon: 'warning',
        buttons: true,
      }

      swal(swal_params).then((isConfirmed) => {
        if (!isConfirmed) return

        $.ajax({
          type: 'GET',
          url: $(event.target).data('url'),
          data: { financial_lead_mapping_ids: [mapping.id] },
          success: () =>
            swal({
              title: 'Last Month Report',
              text: 'Report has been successfully sent',
              icon: 'success',
            }),
          error: () =>
            swal({ title: 'Last Month Report', text: 'Report can not be sent', icon: 'error' }),
        })
      })
    }

    $timeout(() => {
      $element.find('@financial-lead-mappings tr').each((_, el) => {
        const $row = $(el)
        const mappingId = $row.data('mappingid')
        $row.find('@financial-lead-contacts').trigger('change-options', contacts[mappingId])
      })
    })
  },
])
