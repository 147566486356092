import { isEmpty, each } from 'lodash-es'
import { DateHelper } from './date_helper'

export class ZohoInfo {
  static displayDate = (timeInMilliseconds) => {
    const dateTime = new Date(parseInt(timeInMilliseconds))
    return DateHelper.displayDate(dateTime)
  }

  static managedStatus = (managedStatus) => {
    const statuses = {
      0: 'Enrollment failed',
      1: 'Yet to enroll',
      2: 'Enrolled successfully',
      3: 'Waiting for approval',
      4: 'Unmanaged',
      5: 'Waiting for user assignment',
    }
    return statuses[managedStatus]
  }

  static platformName = (platformType) => {
    const platforms = {
      1: 'iOS',
      2: 'Android',
      3: 'Windows',
      4: 'Chrome',
    }
    return platforms[platformType]
  }

  static mapping = {
    id: {
      title: 'Zoho ID',
      path: 'device_id',
    },
    phone_number: {
      title: 'Phone Number',
      path: 'phone_number',
    },
    email_address: {
      title: 'User Email',
      path: 'user.user_email',
    },
    device_name: {
      title: 'Device Name',
      path: 'device_name',
    },
    manufacturer: {
      title: 'Manufacturer',
      path: 'manufacturer',
    },
    model: {
      title: 'Model',
      path: 'model',
    },
    imei: {
      title: 'IMEI',
      path: 'imei',
    },
    platform_name: {
      title: 'Platform Name',
      path: 'os.platform_type',
      formatter: ZohoInfo.platformName,
    },
    os_name: {
      title: 'OS Name',
      path: 'os_name',
    },
    os_version: {
      title: 'OS Version',
      path: 'os_version',
    },
    os_service_pack: {
      title: 'Build Version',
      path: 'build_version',
    },
    last_sync: {
      title: 'Last Sync',
      path: 'last_scan_time',
      formatter: ZohoInfo.displayDate,
    },
    serial_number: {
      title: 'Serial Number',
      path: 'serial_number',
    },
    mac_address: {
      title: 'WiFi Mac Address',
      path: 'network.wifi_mac',
    },
    managed_status: {
      title: 'Managed Status',
      path: 'managed_status',
      formatter: ZohoInfo.managedStatus,
    },
    device_rooted: {
      title: 'Rooted',
      path: 'security.device_rooted',
      formatter: ZohoInfo.managedStatus,
    },
    current_carrier: {
      title: 'Carrier',
      path: 'sim.subscriber_carrier_network',
    },
    roaming_status: {
      title: 'In Roaming',
      path: 'network.is_roaming',
    },
  }

  static groupedAttributes = {
    short: [
      {
        title: 'Device',
        attributeNames: ['imei', 'serial_number', 'manufacturer', 'model'],
      },
      {
        title: 'Info',
        attributeNames: ['phone_number', 'email_address'],
      },
      {
        title: 'Zoho Status',
        attributeNames: ['id', 'last_sync', 'managed_status'],
      },
    ],
    full: [
      {
        title: 'Device',
        attributeNames: [
          'imei',
          'serial_number',
          'manufacturer',
          'model',
          'platform_name',
          'os_name',
          'os_version',
          'os_service_pack',
          'device_name',
          'mac_address',
          'device_rooted',
        ],
      },
      {
        title: 'Info',
        attributeNames: ['phone_number', 'email_address', 'current_carrier', 'roaming_status'],
      },
      {
        title: 'Zoho Status',
        attributeNames: ['id', 'last_sync', 'managed_status'],
      },
    ],
  }

  static buttons = {
    short: [],
    full: [],
  }

  static value = (name) => {
    const value = ZohoInfo.digValue(ZohoInfo.data.base, ZohoInfo.mapping[name].path)
    if (!ZohoInfo.mapping[name].formatter) return value
    return ZohoInfo.mapping[name].formatter(value)
  }

  static digValue = (obj, path) => {
    if (isEmpty(obj)) return
    let current = obj
    each(path.split('.'), (fragment) => {
      if (current[fragment] === undefined) {
        current = 'no data'
        return
      } else {
        current = current[fragment]
      }
    })
    return current
  }

  prepareData(mode) {
    const result = []
    each(ZohoInfo.groupedAttributes[mode], (group) => {
      const groupedAttrs = { attributes: [], title: group.title }
      each(group.attributeNames, (name) => {
        const value = ZohoInfo.value(name)
        if (value) {
          const obj = { title: ZohoInfo.mapping[name].title, value: value }
          groupedAttrs.attributes.push(obj)
        }
      })
      result.push(groupedAttrs)
    })
    return result
  }

  prepareButtons(mode) {
    const result = {}
    each(ZohoInfo.buttons[mode], (name) => (result[name] = true))
    return result
  }

  constructor(data) {
    ZohoInfo.data = data
    this.updatedAt = DateHelper.displayDate(new Date(Date.parse(data.updated_at)))
    this.displayMode = 'full'
    this.displayData = {
      attributeGroups: this.prepareData(this.displayMode),
      buttons: this.prepareButtons(this.displayMode),
    }
  }

  switchViewMode() {
    this.displayMode = this.displayMode === 'short' ? 'full' : 'short'
    this.displayData.attributeGroups = this.prepareData(this.displayMode)
    this.displayData.buttons = this.prepareButtons(this.displayMode)
  }
}
